import { useState, useMemo } from "react";
import { Panel, Input, Button, IconButton } from "rsuite";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import { ArrowLeftLine, ArrowRight } from '@rsuite/icons';
import { useAnalytics } from './AnalyticsContext';

const FeedbackButton = ({ onClick, isSelected, children, variant }) => (
  <Button
    onClick={onClick}
    appearance={isSelected ? "primary" : "subtle"}
    color={variant === 'yes' ? "green" : variant === 'no' ? "red" : null}
    style={{ borderRadius: '20px' }}
  >
    {children}
  </Button>
);

const QuestionList = ({ questions, onSelectQuestion, searchQuery }) => {
  const { t } = useTranslation();

  // Check if there are any questions in any group
  const hasQuestions = Object.values(questions).some(group => group.length > 0);

  return (
    <div style={{ flex: 1, overflowY: 'auto', padding: '0 24px 24px' }}>
      {hasQuestions ? (
        Object.entries(questions).map(([topic, items]) => (
          // Only render topic group if it has items
          items.length > 0 && (
            <div key={topic}>
              <div style={{
                padding: '24px 8px 12px 8px',
                fontSize: '15px',
                color: '#374151',
                fontWeight: 600,
                textTransform: 'capitalize',
                borderBottom: '1px solid #E5E7EB',
                marginBottom: '8px',
                letterSpacing: '0.025em',
                display: 'flex',
                alignItems: 'center',
                gap: '8px'
              }}>
                {topic === 'general' ? t('Yleiset kysymykset') : topic}
              </div>
              {items.map((item) => (
                <Button
                  key={item.id}
                  block
                  appearance="subtle"
                  onClick={() => onSelectQuestion(item.id)}
                  style={{
                    textAlign: 'left',
                    padding: '12px 16px',
                    borderBottom: '1px solid #eee',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <span style={{ fontSize: '14px', whiteSpace: "normal" }}>
                    {item.question}
                  </span>
                  <ArrowRight style={{ fontSize: '14px' }} />
                </Button>
              ))}
            </div>
          )
        ))
      ) : (
        <div style={{ textAlign: 'center', padding: '16px', color: '#666' }}>
          {searchQuery ? "Ei hakutuloksia" : "Ei kysymyksiä saatavilla"}
        </div>
      )}
    </div>
  );
};

const QuestionDetail = ({ question, answer, onBack, onFeedback, feedbackState, feedbackMessage, read_more_url }) => (
  <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <div style={{ padding: '12px', borderBottom: '1px solid #eee' }}>
      <IconButton
        icon={<ArrowLeftLine />}
        appearance="subtle"
        onClick={onBack}
      >
        Takaisin
      </IconButton>
    </div>

    <div style={{ flex: 1, overflowY: 'auto', padding: '16px' }}>
      <h3 style={{ fontSize: '18px', marginBottom: '16px', color: '#111' }}>
        {question}
      </h3>
      <div
        className="faqContent"
        style={{ fontSize: '14px', lineHeight: 1.5, color: '#444' }}
        dangerouslySetInnerHTML={{
          __html: answer.replace(
            /<img(.*?)src="(.*?)"(.*?)>/g,
            '<img$1src="$2"$3 style="cursor:pointer" onclick="window.open(this.src, \'_blank\')">'
          )
        }}
      />
      {
        /*

      <div style={{marginTop:24}}>
        <AnimatePresence mode="wait">
          {feedbackMessage ? (
            <motion.p
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              style={{ fontSize: '14px', color: '#4CAF50', textAlign: 'center' }}
            >
              {feedbackMessage}
            </motion.p>
          ) : (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
            >
              <p style={{ fontSize: '14px', color: '#666', textAlign: 'center', marginBottom: '8px' }}>
                Oliko tästä vastauksesta apua?
              </p>
              <div style={{ display: 'flex', gap: '8px', justifyContent: 'center', marginBottom: 100 }}>
                <FeedbackButton
                  onClick={() => onFeedback(true)}
                  isSelected={feedbackState === true}
                  variant="yes"
                >
                  👍 Kyllä
                </FeedbackButton>
                <FeedbackButton
                  onClick={() => onFeedback(false)}
                  isSelected={feedbackState === false}
                  variant="no"
                >
                  👎 Ei
                </FeedbackButton>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
              */
      }
    </div>

  </div>
);

function HelpCenter({ widgetData }) {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [feedbacks, setFeedbacks] = useState({});
  const [feedbackMessage, setFeedbackMessage] = useState('');

  const analytics = useAnalytics();

  const groupedQuestions = useMemo(() => {
    const filtered = (widgetData?.faq_questions || [])
      .filter(item => {
        const question = typeof item === 'string' ? item : item.question;
        const answer = typeof item === 'string' ? '' : (item.answer || '');
        return (question + answer).toLowerCase().includes(searchQuery.toLowerCase());
      })
      .map((item, index) => ({
        ...item,
        id: index,
        topic: (item?.topic || '').toLowerCase() || 'general'
      }));

    // Group by topic
    const grouped = filtered.reduce((acc, item) => {
      const topic = item.topic;
      if (!acc[topic]) {
        acc[topic] = [];
      }
      acc[topic].push(item);
      return acc;
    }, {});

    // Ensure general is first
    const sortedGrouped = {};
    if (grouped['general']) {
      sortedGrouped['general'] = grouped['general'];
      delete grouped['general'];
    }

    // Add rest of the topics alphabetically
    Object.keys(grouped)
      .sort()
      .forEach(key => {
        sortedGrouped[key] = grouped[key];
      });

    return sortedGrouped;
  }, [widgetData, searchQuery]);

  const handleFeedback = (id, isHelpful) => {
    setFeedbacks(prev => ({ ...prev, [id]: isHelpful }));
    setFeedbackMessage(t('Kiitos palautteesta'));
    setTimeout(() => {
      setSelectedQuestion(null);
      setFeedbackMessage('');
    }, 1000);
  };

  const onOnSelectQuestion = (id) => {
    setSelectedQuestion(id);
    var q = Object.values(groupedQuestions)
      .flat()
      .find(q => q.id === id)?.question
    analytics.trackEvent('faq_item_clicked', { question: q });
  };

  return (
    <Panel
      bordered
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        background: 'white',
        overflow: 'hidden'
      }}
    >
      <AnimatePresence mode="wait">
        {selectedQuestion === null ? (
          <motion.div
            key="list"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <div style={{ padding: '24px', paddingBottom: 0, paddingTop:8 }}>
              <h2 style={{ fontSize: '20px', marginBottom: '12px', textAlign: 'center' }}>
                {t("Usein kysytyt kysymykset")}
              </h2>
              <Input
                type="search"
                placeholder={t("Etsi vastausta kysymykseesi...")}
                value={searchQuery}
                onChange={setSearchQuery}
                style={{ marginBottom: '12px' }}
              />
            </div>
            <QuestionList
              questions={groupedQuestions}
              onSelectQuestion={onOnSelectQuestion}
              searchQuery={searchQuery}
            />
          </motion.div>
        ) : (
          <motion.div
            key="detail"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            style={{ height: '100%' }}
          >
            <QuestionDetail
              question={Object.values(groupedQuestions)
                .flat()
                .find(q => q.id === selectedQuestion)?.question}
              answer={Object.values(groupedQuestions)
                .flat()
                .find(q => q.id === selectedQuestion)?.answer}
              read_more_url={Object.values(groupedQuestions)
                .flat()
                .find(q => q.id === selectedQuestion)?.read_more_url}
              onBack={() => setSelectedQuestion(null)}
              onFeedback={(isHelpful) => handleFeedback(selectedQuestion, isHelpful)}
              feedbackState={feedbacks[selectedQuestion]}
              feedbackMessage={feedbackMessage}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Panel>
  );
}

export default HelpCenter;