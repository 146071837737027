// src/AnalyticsContext.jsx
import { createContext, useContext } from 'react';
import { getApiUrl } from '../../config';
import { UAParser } from 'ua-parser-js';

class BotAnalytics {
  constructor(endpoint, options = {}) {
    this.endpoint = endpoint; // e.g. "https://virtuaaliapuri.azurewebsites.net/api/analytics/"
    // These values will be set from localStorage (or newly generated).
    this.sessionId = null;
    this.visitorId = null;
    this.websiteId = null;
    this.botId = null;
    this.eventQueue = [];
    this.options = {
      batchSize: options.batchSize || 10, // Number of events per batch
      retryAttempts: options.retryAttempts || 3, // Retry attempts for failed requests
      samplingRate: options.samplingRate || 1.0, // Track 100% of events by default
    };
    this.currentChatId = null;
    this._initializingPromise = null; // To hold the promise during initialization

    if (this.options.batchSize > 1) {
      this.startBatching();
    }
  }

  /**
   * Initializes the analytics session.
   * Instead of calling a backend endpoint, we use localStorage to persist the session.
   * This method returns an object containing colSessionID, colVisitorID, and colWebsiteID.
   */
  async initialize() {
    // If already initialized, return immediately.
    if (this.sessionId) {
      return {
        colSessionID: this.sessionId,
        colVisitorID: this.visitorId,
        colWebsiteID: this.botId
      };
    }

    // If initialization is in progress, return that promise.
    if (this._initializingPromise) {
      return this._initializingPromise;
    }

    this._initializingPromise = (async () => {
      // Check localStorage for existing session data.
      const stored = localStorage.getItem('botAnalyticsSession');
      if (stored) {
        try {
          const data = JSON.parse(stored);
          // Basic validation: if a sessionId exists, use it.
          if (data && data.colSessionID) {
            this.sessionId = data.colSessionID;
            this.visitorId = data.colVisitorID;
            this.websiteId = data.botId;
            console.log('Analytics restored from localStorage:', data);
            return data;
          }
        } catch (error) {
          console.error('Error parsing stored analytics data:', error);
          localStorage.removeItem('botAnalyticsSession');
        }
      }

      // If no valid data in localStorage, generate new IDs.
      this.sessionId = `session_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
      this.visitorId = `visitor_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
      // For website, we can use the hostname as a constant value.
      const newData = {
        colSessionID: this.sessionId,
        colVisitorID: this.visitorId,
        colWebsiteID: this.botId
      };
      localStorage.setItem('botAnalyticsSession', JSON.stringify(newData));
      console.log('Analytics initialized and stored in localStorage:', newData);
      return newData;
    })();

    // Wait for initialization to complete.
    const result = await this._initializingPromise;
    this._initializingPromise = null; // Clear the promise for future calls.
    return result;
  }

  generateChatId() {
    return `chat_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
  }

  async startChat(openingMessage = null) {
    this.currentChatId = this.generateChatId();
    const parser = new UAParser();
    const deviceDetails = parser.getResult();
    await this.trackEvent('chat_start', {
      chatId: this.currentChatId,
      openingMessage,
      url: window.location.href,
      device: {
        browser: deviceDetails.browser.name,
        os: deviceDetails.os.name,
        device: deviceDetails.device.type || 'Desktop',
      },
    });
    return this.currentChatId;
  }

  async trackChatLoad(url) {
    await this.trackEvent('page_view', { url });
  }

  async trackChatOpen(openingMessage = null) {
    this.currentChatId = this.generateChatId();
    await this.trackEvent('chat_open', {
      chatId: this.currentChatId,
      openingMessage,
      url: window.location.href,
    });
  }

  async trackClick(targetElement, metadata = {}) {
    await this.trackEvent('click', {
      targetElement, // You might send a string representation of the element (e.g. tag, id, etc.)
      metadata,
    });
  }

  async trackFormSubmit(formData, stepId) {
    if (!this.currentChatId) return;
    await this.trackEvent('form_submit', {
      chatId: this.currentChatId,
      formData,
      stepId,
    });
  }

  async trackFaqInteraction(topic, helpful) {
    await this.trackEvent('faq_interaction', { topic, helpful });
  }

  async trackError(errorDetails) {
    await this.trackEvent('error', { errorDetails });
  }

  async trackTabChange(tabName) {
    await this.trackEvent('tab_change', { tabName });
  }

  async trackChatClose(reason) {
    if (!this.currentChatId) return;
    await this.trackEvent('chat_close', { chatId: this.currentChatId, reason });
    this.currentChatId = null;
  }

  async trackSessionStart() {
    await this.trackEvent('session_start', { url: window.location.href });
  }

  /**
   * Tracks an event by sending it to the backend (or handling it via batching).
   * If sessionId is not set, this method calls initialize() first.
   */
  async trackEvent(eventType, details = {}) {
    if (!this.sessionId) {
      try {
        await this.initialize();
      } catch (error) {
        console.error('Failed to initialize analytics; sessionId is not set.');
        return;
      }
      if (!this.sessionId) {
        console.error('Failed to initialize analytics; sessionId is still not set.');
        return;
      }
    }

    // Sampling: only send event if random check passes.
    if (Math.random() > this.options.samplingRate) return;

    const event = {
      colSessionID: this.sessionId,
      colEventType: eventType,
      colEventData: JSON.stringify(details),
      timestamp: Date.now(),
    };

    if (this.options.batchSize > 1) {
      this.eventQueue.push(event);
      if (this.eventQueue.length >= this.options.batchSize) {
        await this.flushEventQueue();
      }
    } else {
      await this.sendEvents([event]);
    }
  }

  async trackMessage(message, stepId) {
    if (!this.currentChatId) return;
    await this.trackEvent('chat_message', {
      chatId: this.currentChatId,
      message: {
        type: message.type,
        content: message.content,
        stepId,
      },
    });
  }

  async endChat() {
    if (!this.currentChatId) return;
    await this.trackEvent('chat_end', { chatId: this.currentChatId });
    this.currentChatId = null;
  }

  async flushEventQueue() {
    if (this.eventQueue.length === 0) return;
    const eventsToSend = [...this.eventQueue];
    this.eventQueue = [];
    await this.sendEvents(eventsToSend);
  }

  async sendEvents(events) {
    let attempts = 0;
    while (attempts < this.options.retryAttempts) {
      try {
        const response = await fetch(`${this.endpoint}event`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(events),
        });
        if (!response.ok) {
          throw new Error('Failed to track events');
        }
        break; // Exit loop if successful.
      } catch (error) {
        console.error('Analytics error:', error);
        attempts++;
        if (attempts >= this.options.retryAttempts) {
          console.error('Failed to send events after retries:', events);
        }
      }
    }
  }

  startBatching() {
    setInterval(() => this.flushEventQueue(), 5000);
  }
}

const AnalyticsContext = createContext(null);

export function AnalyticsProvider({ children, options }) {
  const endpoint = getApiUrl() + "/analytics/"; // Ensure trailing slash.
  const analytics = new BotAnalytics(endpoint, options);
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
}

export function useAnalytics() {
  const analytics = useContext(AnalyticsContext);
  if (!analytics) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }
  return analytics;
}
