import React from 'react';
import { useState, useEffect } from 'react';
import { Input } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPhone,
    faEnvelope,
    faComment,
    faComments,
    faPaperPlane,
    faExternalLink
} from '@fortawesome/free-solid-svg-icons';
import HelpCenter from './HelpCenter';
import {
    FaComment, FaArrowLeft,
    FaSearch, FaRedo, FaTimes, FaChevronDown
} from 'react-icons/fa';
import ContactForm from './ContactFormDefault';
import './openwidget.css';
import { getApiUrl } from '../../config';
import ChatSimple from './ChatSimple';
import { useAttentionTriggers } from './hooks/useAttentionTriggers';
import "bootstrap/dist/css/bootstrap.css";
import { FloatingFooter } from './FloatingFooter';
import NewsletterSubscribe from './NewsletterSubscribe';
import { useParentEvents } from './hooks/useParentEvents';
import { AnalyticsProvider } from './AnalyticsContext.jsx';
import { useAnalytics } from './AnalyticsContext';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { motion, AnimatePresence } from 'framer-motion';


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            "en": {
                "translation": {
                    "Lähetä ✓": "Send ✓",
                    "Seuraava →": "Next →",
                    "Seuraava": "Next",
                    "Ota yhteyttä": "Contact Us",
                    "Valitse aihe ja kirjoita viestisi": "Select a topic and write your message",
                    "Aihe": "Subject",
                    "Viesti": "Message",
                    "Kirjoita viestisi tähän": "Type your message here",
                    "Yleinen tiedostelu": "General Inquiry",
                    "Tarjouspyyntö": "Request for Quotation",
                    "Myynti": "Sales Inquiry",
                    "Asiakaspalvelu": "Customer Service",
                    "Reklamaatio": "Complaint",
                    "Muu asia": "Other Topic",
                    "Täytä yhteystietosi, niin olemme sinuun yhteydessä pian.": "Please fill in your contact details, and we will get back to you shortly.",
                    "Nimi": "Name",
                    "Sähköposti": "Email",
                    "Puhelin": "Phone",
                    "Lähetä viesti": "Send Message",
                    "Takaisin": "Back",
                    "Etunimi Sukunimi": "First Last Name",
                    "email@esimerkki.fi": "email@example.com",
                    "040 1234567": "040 1234567",
                    "Usein kysytyt kysymykset": "Frequently Asked Questions",
                    "Etsi vastausta kysymykseesi...": "Search for an answer to your question",
                    "Oliko tästä vastauksesta apua?": "Was this answer helpful?",
                    "Kyllä": "Yes",
                    "Ei": "No",
                    "Pikalinkit": "Quick Links",
                    "Aihe on pakollinen": "Subject is required",
                    "Viesti on pakollinen": "Message is required",
                    "Nimi on pakollinen": "Name is required",
                    "Tarkista sähköpostiosoite": "Check the email address",
                    "Sähköposti on pakollinen": "Email is required",
                    "Yleinen tiedustelu": "General Inquiry",
                    "Tarjouspyyntö": "Request for Quotation",
                    "Myynti": "Sales Inquiry",
                    "Asiakaspalvelu": "Customer Service",
                    "Reklamaatio": "Complaint",
                    "Muu asia": "Other Topic",
                    "Valitse aihe": "Select Topic",
                    "Kiitos viestistäsi!": "Thank you for your message!",
                    "Olemme sinuun yhteydessä mahdollisimman pian.": "We will get in touch with you as soon as possible.",
                    "Virheellinen sähköpostiosoite": "Invalid email address",
                    "Virheellinen puhelinnumero (esim. +358 40 1234567 tai 040 1234567)": "Invalid phone number (e.g., +358 40 1234567 or 040 1234567)",
                    "Virheellinen postinumero (5 numeroa)": "Invalid postal code (5 digits)",
                    "Virheellinen nimi (vähintään 2 kirjainta)": "Invalid name (at least 2 characters)",
                    "Virheellinen arvo": "Invalid value",
                    "Kiitos palautteesta": "Thank you for your feedback",
                    "Ei hakutuloksia": "No search results",
                    "Uusi viesti!": "New message!",
                    "Soita numeroon {{url}}?": "Call the number {{url}}?",
                    "Lähetä sähköpostia osoitteeseen {{url}}?": "Send an email to {{url}}?",
                    "Avaa WhatsApp-keskustelu?": "Open WhatsApp chat?",
                    "Avaa Messenger-keskustelu?": "Open Messenger chat?",
                    "Avaa Telegram-keskustelu?": "Open Telegram chat?",
                    "Siirry osoitteeseen {{url}}?": "Go to the address {{url}}?",
                    "Yleiset kysymykset": "General Questions"
                }
            },
            fi: {
                translation: {
                    "Lähetä ✓": "Lähetä ✓",
                    "Seuraava →": "Seuraava →",
                    "Seuraava": "Seuraava",
                    "Ota yhteyttä": "Ota yhteyttä",
                }
            },
        },
        lng: "fi", // if you're using a language detector, do not define the lng option
        fallbackLng: "fi",

        interpolation: {
            escapeValue: false
        }
    });

const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />);
Textarea.displayName = 'Textarea';

const bubbleStyle = {
    position: 'absolute',
    bottom: 'calc(100% + 15px)',
    right: -10,
    backgroundColor: 'transparent',
    padding: '10px',
    borderRadius: '18px',
    fontSize: '16px',
    fontWeight: 500,
    color: '#2c2c2c',
    whiteSpace: 'normal',
    zIndex: 9999999,
    maxWidth: '340px',
    minWidth: '320px',
    maxHeight: '600px',
    overflowY: 'auto',
    animation: 'bubblePop 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both',
    lineHeight: '1.4',
    transform: 'scale(1)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.3s ease',
    '&:hover': {
        transform: 'scale(1.02)',
    },
    // Kolmio
    '&:after': {
        content: '""',
        position: 'absolute',
        bottom: '-8px',
        right: '28px',
        width: '16px',
        height: '16px',
        backgroundColor: '#ffffff',
        transform: 'rotate(45deg)',
        boxShadow: '4px 4px 8px rgba(0,0,0,0.1)',
        border: '1px solid rgba(0,0,0,0.1)',
        borderTop: 'none',
        borderLeft: 'none'
    },

    // Scrollbarin tyylit
    '&::-webkit-scrollbar': {
        width: '6px'
    },
    '&::-webkit-scrollbar-track': {
        background: 'rgba(0,0,0,0.05)',
        borderRadius: '3px'
    }
};

export const VirtuaaliApuriWidget = ({ onToggle, id, onClickOffer, businessInformation }) => {

    return (
        <AnalyticsProvider botId={id}>
            <VirtuaaliApuriWidgetInternal onToggle={onToggle} id={id} onClickOffer={onClickOffer} businessInformation={businessInformation} />
        </AnalyticsProvider>
    );
}


export function VirtuaaliApuriWidgetInternal({ onToggle, id, onClickOffer, businessInformation }) {
    const [widgetData, setWidgetData] = useState({});
    const [openingMessage, setOpeningMessage] = useState(false);
    const [innerWindowOpen, setInnerWindowOpen] = useState(false);
    const [currentActiveWindow, setCurrentActiveWindow] = useState("home");
    const [chatKey, setChatKey] = useState(0);
    const [hasShownAutomatic, setHasShownAutomatic] = useState(false);
    const [messages, setMessages] = useState([]);
    const [closedMessages, setClosedMessages] = useState(new Set());
    const [isMinimized, setIsMinimized] = useState(true);
    const MAX_OPEN_MESSAGES = 2;
    const [activeBanner, setActiveBanner] = useState(null);
    const { t } = useTranslation();
  
    const parentEvents = useParentEvents();
    const { attentionState } = useAttentionTriggers(isMinimized, hasShownAutomatic, widgetData?.__TRIGGER_SETTINGS, parentEvents);
    const analytics = useAnalytics();
  
    // Initialize analytics once on component mount.
    useEffect(() => {
      analytics.initialize();
      analytics.trackEvent('session_start', { url: window.location.href });
    }, []);

    useEffect(() => {
      analytics.trackEvent('openingMessage', { openingMessage });
    }, [openingMessage]);

        // New functions for parent communication
        const sendToParent = (type, data = {}) => {
            window.parent.postMessage({ type, ...data }, '*');
        };
  
    // Track attention trigger events.
    // Whenever widgetData.language changes, switch i18n's language
    React.useEffect(() => {
        const availableLanguages = Object.keys(i18n.options.resources || {}); // Get available languages
        const requestedLanguage = widgetData?.language;

        if (requestedLanguage && availableLanguages.includes(requestedLanguage)) {
            i18n.changeLanguage(requestedLanguage); // Change language if it exists
        } else if (requestedLanguage) {
            console.warn(`Requested language "${requestedLanguage}" does not exist. Falling back to default.`);
            i18n.changeLanguage(i18n.options.fallbackLng); // Use fallback language
        }
    }, [widgetData?.language]);
    useEffect(() => {
      if (attentionState?.isActive && attentionState?.message?.length > 0) {
        if (attentionState.type === 'BANNER') {
          setActiveBanner({
            id: Date.now(),
            message: attentionState.message,
            optionalOpeningTopic: attentionState.optionalOpeningTopic
          });
          sendToParent('showBanner');
        } else {
          setMessages(prev => {
            const messageExists = prev.some(msg => msg.message === attentionState.message);
            if (messageExists) return prev;
  
            const newMessages = [
              ...prev,
              {
                id: Date.now(),
                message: attentionState.message,
                optionalOpeningTopic: attentionState.optionalOpeningTopic
              }
            ];
            sendToParent('showMessages', { messageCount: newMessages.length });
            return newMessages;
          });
        }
        analytics.trackEvent('trigger_shown', { attentionState });
      }
    }, [attentionState]);
  
    // Handle banner closure.
    const handleCloseBanner = () => {
      setActiveBanner(null);
      sendToParent('hideBanner');
      analytics.trackEvent('banner_closed');
    };
  
    // Toggle widget minimization/maximization.
    const handleMinimize = () => {
      if (!isMinimized) {
        setIsMinimized(true);
        setInnerWindowOpen(false);
        sendToParent('widgetToggle', { state: true });
        sendToParent('hideMessages');
        if (activeBanner) {
          sendToParent('hideBanner');
          setActiveBanner(null);
        }
        analytics.trackEvent('widget_toggle', { state: 'minimized' });
        onToggle && onToggle(true);
      } else {
        setIsMinimized(false);
        setCurrentActiveWindow('Koti');
        sendToParent('widgetToggle', { state: false });
        analytics.trackEvent('widget_toggle', { state: 'maximized' });
        onToggle && onToggle(false);
      }
      setMessages([]);
    };
  
    // Handle notification message closure.
    const handleCloseNotificationMessage = (messageId) => {
      setClosedMessages(prev => {
        const newSet = new Set([...prev, messageId]);
        const remainingMessages = messages.filter(msg => !newSet.has(msg.id));
  
        if (remainingMessages.length === 0) {
          sendToParent('hideMessages');
        } else {
          sendToParent('showMessages', { messageCount: remainingMessages.length });
        }
        analytics.trackEvent('notification_closed', { messageId });
        return newSet;
      });
    };
  
    // Handle click on a notification to open chat.
    const handleOpenChatViaNotification = (msg, e) => {
      e.stopPropagation();
      const topicId = msg?.optionalOpeningTopic;
      analytics.trackEvent('notification_clicked', {
        messageId: msg.id,
        hasTopicId: !!topicId
      });
      if (topicId) {
        setOpeningMessage({ topicId });
        setInnerWindowOpen(true);
      }
      setIsMinimized(false);
      handleCloseNotificationMessage(msg.id);
      sendToParent('widgetToggle', { state: false });
      onToggle && onToggle(false);
    };
  
    // Listen for changes in the minimized state.
    useEffect(() => {
      if (isMinimized) {
        setInnerWindowOpen(false);
        sendToParent('widgetToggle', { state: true });
      } else {
        sendToParent('widgetToggle', { state: false });
      }
    }, [isMinimized]);
  
    // Restart chat handler.
    const handleRestartChat = () => {
      setChatKey(prevKey => prevKey + 1);
      analytics.trackEvent('chat_restarted', {});
    };
  
    // Fetch widget data.
    useEffect(() => {
      const fetchWidgetData = async () => {
        try {
          if (!id) return;
          const response = await fetch(`${getApiUrl()}/getBotInfo/${id}`);
          if (!response.ok) return;
          const botData = await response.json();
          setWidgetData(botData);
        } catch (e) {
          console.error('Error fetching bot data:', e);
        }
      };
  
      if (businessInformation) {
        setWidgetData(businessInformation);
      } else {
        fetchWidgetData();
      }
    }, [id, businessInformation]);
  
    // Inject custom CSS if provided.
    useEffect(() => {
      if (!widgetData?.custom_css) return;
  
      const styleElement = document.createElement('style');
      styleElement.setAttribute('type', 'text/css');
      styleElement.setAttribute('id', `widget-custom-css-${widgetData.bot_id}`);
      styleElement.textContent = widgetData.custom_css;
      document.head.appendChild(styleElement);
  
      return () => {
        const existingStyle = document.getElementById(`widget-custom-css-${widgetData.bot_id}`);
        if (existingStyle) {
          document.head.removeChild(existingStyle);
        }
      };
    }, [businessInformation?.custom_css, businessInformation?.bot_id, widgetData?.bot_id]);
  
    // Ensure inner window is closed when minimized.
    useEffect(() => {
      if (isMinimized) {
        setInnerWindowOpen(false);
      }
    }, [isMinimized]);
  
    if (!widgetData?.bot_id) {
      return <></>;
    }
  
    let customStyles = {};
    const onChangeFooter = (activeWindow) => {
      setCurrentActiveWindow(activeWindow);
    };

    return (
        <div className="virtuaali-apuri-widget">
            {OpenWidgetButton(handleMinimize, widgetData, isMinimized, customStyles, messages, closedMessages, MAX_OPEN_MESSAGES, handleOpenChatViaNotification, handleCloseNotificationMessage, t)}
            <div
                className="ow-159tj6x e1ujklng0 maincontianer"
                style={{
                    width: "100%",
                    minWidth: "0px",
                    margin: "0px",
                    padding: "0px",
                    backgroundImage: "none",
                    backgroundPosition: "0% 0%",
                    backgroundSize: "initial",
                    backgroundAttachment: "scroll",
                    backgroundOrigin: "initial",
                    backgroundClip: "initial",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderWidth: "0px",
                    float: "none",
                    colorScheme: "normal",
                    position: "fixed",
                    display: isMinimized ? 'none' : 'block',
                    visibility: "visible",
                    opacity: 1,
                    pointerEvents: isMinimized ? 'none' : 'auto',
                    maxWidth: 380,
                    minHeight: "300px",
                    zIndex: 9999998,
                    height: '100%',
                    maxHeight: '720px'
                }}
            >
                <AnimatePresence mode="wait">
                    <div
                        className="ow-1ttnkpf e1ujklng1"
                        style={{ width: "100%", height: "100%" }}
                    >
                        <div id="widget-global-nd6bpniszq" style={{ boxSizing: "border-box" }}>
                            <div
                                data-testid="maximized"
                                id="chat-widget-maximized"
                                dir="ltr"
                                className="lc-1dvk0j5 edvz03i3 lc-12vmhyu-appear-done lc-12vmhyu-enter-done"
                                style={{

                                    position: "absolute",
                                    inset: "0px 0px 0px auto",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >

                                <div
                                    role="main"
                                    className="lc-1483f3g edvz03i2 kokoWidget"
                                    style={{
                                        position: "relative",
                                        display: "flex",
                                        flexDirection: "column",
                                        minWidth: 0,
                                        height: "100%",
                                        overflow: "auto",
                                        backgroundPosition: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                        border: widgetData?.widgetBorderColor ? widgetData?.widgetBorderColor : "1px solid #bfb5b5",
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                        border: "1px solid rgb(111 111 111 / 45%)",
                                        borderRadius: "16px"
                                    }}
                                >
                                    <div
                                        id="overlay_portal_container"
                                        className="lc-l7pcb5 e196iwy70"
                                        style={{
                                            zIndex: 102,
                                            borderRadius: "inherit"
                                        }}
                                    />
                                    <div
                                        id="tooltip_portal_container"
                                        className="lc-2gmwhl e196iwy71"
                                        style={{


                                            zIndex: 102,
                                            position: "relative"
                                        }}
                                    />
                                    <span
                                        data-focus-scope-start="true"
                                        hidden=""
                                    />
                                    <WidgetHeader
                                        widgetData={widgetData}
                                        currentActiveWindow={currentActiveWindow}
                                        innerWindowOpen={innerWindowOpen}
                                        setInnerWindowOpen={setInnerWindowOpen}
                                        handleRestartChat={handleRestartChat}
                                        handleMinimize={handleMinimize}
                                        isMinimized={isMinimized}
                                    />
                                    <div
                                        className="lc-1w7ftyl e1ckmroo0 wholeBot"
                                        style={{
                                            overflowY: "auto",
                                            height: "100%",
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            zIndex: 1,
                                            backgroundColor: "white"
                                        }}
                                    >
                                        <FloatingFooter
                                            activeColor={widgetData?.secondary_theme_color}
                                            isVisible={!innerWindowOpen}
                                            onChange={onChangeFooter}
                                            bgColor={widgetData?.footerBackgroundColor}
                                            settings={widgetData}
                                        />
                                        <div
                                            className="lc-vrrmai e15rqx31"
                                            style={{


                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: 245,
                                                zIndex: 1
                                            }}
                                        >
                                            <div
                                                className="lc-zm9xlu ecmztab2"
                                                style={{


                                                    position: "absolute",
                                                    height: "100%",
                                                    width: "100%",
                                                    opacity: 1,
                                                    backgroundPosition: "initial",
                                                    backgroundOrigin: "initial",
                                                    backgroundClip: "initial",
                                                    backgroundColor: "initial"
                                                }}
                                            />
                                            {
                                                widgetData?.dontDisplayBackground && <></> ||
                                                <div
                                                    className="lc-1t20syh ecmztab1 backgroundImage"
                                                    style={{
                                                        position: "absolute",
                                                        height: "100%",
                                                        width: "100%",
                                                        backgroundPosition: "initial",
                                                        backgroundOrigin: "initial",
                                                        backgroundClip: "initial",
                                                        backgroundColor: "initial",
                                                        backgroundSize: "cover",  // Makes image cover the container
                                                        backgroundRepeat: "no-repeat",  // Prevents image from repeating
                                                        overflow: "hidden",  // Prevents content from spilling out
                                                        left: 0,  // Ensures proper alignment
                                                        right: 0,
                                                        transform: innerWindowOpen ? 'scale(0.95)' : 'scale(1)',
                                                        opacity: innerWindowOpen ? 0 : 1,
                                                        transition: 'transform 0.2s ease-out, opacity 0.2s ease-out',
                                                        ...(widgetData?.backgroundImageUrl && { backgroundImage: `url(${widgetData.backgroundImageUrl})` }),
                                                        ...(widgetData?.backgroundImageCss || {})
                                                    }}
                                                >
                                                </div>
                                            }
                                        </div>

                                        <div
                                            style={{
                                                transform: `translateY(${innerWindowOpen ? '0' : '100%'})`,
                                                //transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)', // smoother easing
                                                height: '100%',
                                                backgroundColor: 'transparent',
                                                zIndex: innerWindowOpen ? 3 : -1,
                                                overflow: innerWindowOpen ? 'hidden' : 'hidden', // Always hide overflow
                                                display: 'flex',
                                                flexDirection: 'column',
                                                willChange: 'transform', // Optimize for transform changes
                                            }}
                                        >
                                            {currentActiveWindow === 'Koti' &&
                                                <div style={{
                                                    flex: 1,
                                                    position: 'relative',
                                                    overflow: 'hidden',
                                                    opacity: innerWindowOpen ? 1 : 0, // Fade in/out
                                                    //transition: 'opacity 0.3s ease-out',
                                                }}>
                                                    <ChatSimple 
                                                    innerWindowOpen={innerWindowOpen} 
                                                    chatKey={chatKey} 
                                                    widgetData={widgetData} 
                                                    openingMessage={openingMessage} />
                                                </div>
                                            }
                                        </div>

                                        {currentActiveWindow === 'Yhteys' &&
                                            <div style={{ padding: 30, zIndex: 10, paddingTop: 0, minHeight: '100%', backgroundColor: "white" }}>
                                                {<ContactForm widgetData={widgetData} themeColor={widgetData?.theme_color} onGoBack={() => setSendMsg(false)} />
                                                }
                                            </div>
                                        }
                                        {currentActiveWindow === 'Apu' &&

                                            <div style={{ minHeight: '100%', background: "white", zIndex: 999 }}>
                                                <HelpCenter widgetData={widgetData} />
                                            </div>

                                        }


                                        {!innerWindowOpen && currentActiveWindow === 'Koti' &&
                                            KotiSection(widgetData, setInnerWindowOpen, setOpeningMessage, t, ButtonLinksSection)
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </AnimatePresence>
            </div>
        </div >
    )

    function ButtonLinksSection() {
        return <div
            data-testid="contact-card"
            className="lc-br0dab eclvk762"
            style={{
                borderRadius: 12,
                display: "flex",
                overflow: "hidden auto",
                flexDirection: "column",
                maxWidth: "100%",
                marginBottom: "20px",
                width: "100%",
                padding: 16
            }}
        >
            <div
                className="lc-14ugvnu eclvk761 pikalinkitDiv"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    minWidth: "0%",
                    maxWidth: "100%",
                    color: "rgb(17, 17, 17)",
                    height: "100%",
                    width: "100%",
                    justifyContent: "space-between"
                }}
            >
                {widgetData?.button_links?.map((link, index) => {
                    const text = typeof link === 'string' ? link : link.text;
                    const url = typeof link === 'string' ? '#' : (link.url || '#');
                    const type = typeof link === 'string' ? 'link' : (link.type || 'link');

                    // Get appropriate icon based on type
                    const getIcon = (type) => {
                        switch (type) {
                            case 'tel':
                                return faPhone;
                            case 'email':
                                return faEnvelope;
                            case 'whatsapp':
                                return faComment; // Alternative for WhatsApp
                            case 'messenger':
                                return faComments; // Alternative for Messenger
                            case 'telegram':
                                return faPaperPlane; // Alternative for Telegram
                            default:
                                return faExternalLink;
                        }
                    };

                    // Get appropriate href based on type
                    const getHref = (type, url) => {
                        switch (type) {
                            case 'tel':
                                return `tel:${url}`;
                            case 'email':
                                return `mailto:${url}`;
                            case 'whatsapp':
                                return `https://wa.me/${url.replace(/[^0-9]/g, '')}`;
                            case 'messenger':
                                return `https://m.me/${url}`;
                            case 'telegram':
                                return `https://t.me/${url}`;
                            default:
                                return url;
                        }
                    };

                    // Get confirmation message based on type
                    const getConfirmMessage = (type, url) => {
                        switch (type) {
                            case 'tel':
                                return t('Soita numeroon {{url}}?', { url });
                            case 'email':
                                return t('Lähetä sähköpostia osoitteeseen {{url}}?', { url });
                            case 'whatsapp':
                                return t('Avaa WhatsApp-keskustelu?');
                            case 'messenger':
                                return t('Avaa Messenger-keskustelu?');
                            case 'telegram':
                                return t('Avaa Telegram-keskustelu?');
                            default:
                                return t('Siirry osoitteeseen {{url}}?', { url });
                        }
                    };

                    return (
                        <button
                            key={index}
                            type="button"
                            className="eclvk764 lc-rvkpez e898cdi0"
                            style={{
                                fontFamily: "inherit",
                                textAlign: "center",
                                overflow: "hidden",
                                width: "100%",
                                outlineOffset: 2,
                                padding: 12,
                                color: widgetData?.quick_links_btn_text_color ?? "rgb(255, 255, 255)",
                                backgroundColor: widgetData?.quick_links_btn_color ?? '#007bff',
                                gridTemplate: '"icon cta chevron" min-content ". draft ." 1fr / 20px auto 20px',
                                columnGap: 8,
                                lineHeight: "16.8px",
                                fontSize: 14,
                                fontWeight: "bold",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                                position: "relative",
                                marginBottom: 4,
                                borderWidth: "initial",
                                borderStyle: "none",
                                borderColor: "initial",
                                borderRadius: 6,
                            }}
                            onClick={() => {
                                if (window.confirm(getConfirmMessage(type, url))) {
                                    window.open(getHref(type, url), '_blank');
                                }
                            }}
                        >
                            {text} <FontAwesomeIcon icon={getIcon(type)} style={{ marginLeft: "auto" }} />
                        </button>
                    );
                })}
            </div>
        </div>;
    }
}



const WidgetHeader = ({
    widgetData,
    currentActiveWindow,
    innerWindowOpen,
    setInnerWindowOpen,
    handleRestartChat,
    handleMinimize,
    isMinimized
}) => {
    return (
        <AnimatePresence>
            {!isMinimized && (
                <div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="lc-6vuy1u e10snzb05"
                    style={{
                        position: "sticky",
                        width: "100%",
                        zIndex: 2,
                        top: 0,
                        background: widgetData?.transparentHeader && currentActiveWindow === 'Koti' && !innerWindowOpen 
                            ? 'transparent'
                            : widgetData?.theme_color_header,
                        boxShadow: (widgetData?.transparentHeader && currentActiveWindow === 'Koti' && !innerWindowOpen)
                            ? 'none'
                            : '0px 3px 15px rgb(0 0 0 / 18%)',
                        height: '64px',
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            width: "85%",
                            padding: "0px 0",
                            position: 'absolute',
                            top: '4px'
                        }}
                    >
                        {widgetData?.logo?.length > 5 && (
                            <div style={{
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center',
                                position: 'relative'
                            }}>
                                {innerWindowOpen ? (
                                    <div
                                        initial={{ opacity: 0, x: -20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        exit={{ opacity: 0, x: -20 }}
                                        transition={{ duration: 0.2 }}
                                        style={{
                                            marginLeft: 14,
                                            marginTop: 16,
                                            cursor: 'pointer',
                                            zIndex:99
                                        }}
                                        onClick={() => setInnerWindowOpen(false)}
                                    >
                                        <FaArrowLeft
                                            style={{
                                                fontSize: 20,
                                                color: 'rgb(219 219 219)',
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <img
                                        initial={{ opacity: 0, y: -50 }}
                                        animate={{
                                            opacity: 1,
                                            y: 0,
                                        }}
                                        exit={{ opacity: 0, y: 50 }}
                                        transition={{
                                            delay: 0.2,
                                            duration: 0.3,
                                            type: "spring",
                                            stiffness: 260,
                                            damping: 20
                                        }}
                                        src={widgetData.logo}
                                        style={{
                                            maxWidth: 80,
                                            maxHeight: 40,
                                            marginTop: 6,
                                            marginLeft: 14,
                                            display: widgetData?.doNotDisplayLogoMainPage ? "none" : "initial",
                                        }}
                                        alt="logo"
                                    />
                                )}
                            </div>
                        )}
                    </div>

                    <div
                        className="e10snzb07 lc-1i4hm43 emevikl1"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            borderWidth: "initial",
                            borderStyle: "initial",
                            borderColor: "rgb(0, 0, 0)",
                            color: "rgb(17, 17, 17)",
                            fontWeight: 700,
                            fontSize: 16,
                            textAlign: "center",
                            minHeight: 56,
                            padding: "0.8em",
                            backgroundColor: "transparent",
                            top: 0,
                            zIndex: 3,
                            position: "relative",
                        }}
                    >
                        <div
                            className="lc-1q5pgrf emevikl0"
                            style={{
                                margin: "0px 0px 0px 32px",
                                width: "100%",
                                padding: "0px 2px",
                                flexGrow: 1,
                                fontSize: 14,
                                overflow: "hidden"
                            }}
                        >
                            <span
                                className="lc-22bwi3 e10snzb06"
                                style={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "transparent"
                                }}
                            />
                        </div>

                            {innerWindowOpen && (
                                <button
                                    initial={{ opacity: 0, y: -20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    transition={{ delay: 0.3 }}
                                    type="button"
                                    aria-label="restart fönster"
                                    data-testid="restart"
                                    onClick={handleRestartChat}
                                    style={{
                                        backgroundColor: "inherit"
                                    }}
                                >
                                    <FaRedo style={{ marginTop: 3 }} size={20} color='#fff' />
                                </button>
                            )}

                        <button
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.4 }}
                            type="button"
                            aria-label="Minimera fönster"
                            data-testid="minimize"
                            onClick={handleMinimize}
                            className="ea5qvgz0 lc-ebkyw6 ejbfa1m0"
                            style={{
                                backgroundColor: "inherit"
                            }}
                        >
                            <FaTimes
                                style={{ marginTop: 3 }}
                                size={20}
                                color={widgetData?.formTextColor ? widgetData?.formTextColor : 'rgb(219 219 219)'}
                            />
                        </button>
                    </div>
                </div>
            )}
        </AnimatePresence>
    );
};

export default WidgetHeader;

function KotiSection(widgetData, setInnerWindowOpen, setOpeningMessage, t, ButtonLinksSection) {
    return <div
        id="homescreen-wrapper"
        className="lc-7f33bh e15rqx36"
        style={{
            minHeight: '100%',
            msOverflowStyle: "none", // IE and Edge
            scrollbarWidth: "none", // Firefox
            "&::-webkit-scrollbar": {
                display: "none" // Chrome, Safari, newer versions of Opera
            },
            paddingBottom: 10,
            width: "100%",
            overflowY: "auto",
            flexGrow: 1,
            position: "relative",
            zIndex: 2
        }}
    >
        <div
            className="lc-1qf3q98 e15rqx30"
            style={{
                marginTop: 64,
                display: "flex",
                flexDirection: "column",
                alignItems: "baseline",
                padding: "32px 16px 0px"
            }}
        >
            <h1
                fontSize="2rem"
                className="lc-vadyec ew75jgp1"
                style={{
                    fontWeight: "bold",
                    fontSize: 28,
                    textAlign: "left",
                    margin: 0,
                    wordBreak: "break-word",
                    zIndex: 1
                }}
            >
                <span
                    className="lc-16vzuxh ew75jgp0 aloituskysymyksetTitle"
                    style={{
                        backgroundPosition: "initial",
                        backgroundOrigin: "initial",
                        backgroundColor: "rgb(17, 17, 17)",
                        backgroundClip: "text",
                        whiteSpace: "pre-wrap"
                    }}
                >
                    {widgetData?.mainGreeting ?? 'Tervetuloa!'}
                </span>
            </h1>
        </div>
        <div
            className="lc-8jt5tj e15rqx35"
            style={{
                position: "relative",
                zIndex: 2,
                marginTop: 20
            }}
        >
            <div
                className="lc-xittol e15rqx34"
                style={{
                    padding: "0px 12px",
                    marginBottom: 16
                }}
            >
                <p
                    style={{
                        color: '#6a6a6abf',
                        fontSize: '16px',
                        fontWeight: '500',
                        marginLeft: '24px',
                        marginBottom: '10px',
                        marginTop: '-12px',
                        padding: 2,
                        display: "none"
                    }}
                >{widgetData?.primary_button_action_support_text}</p>
            </div>
            {aloitusKysymyksetComponent(widgetData, setInnerWindowOpen, setOpeningMessage)}
                    {
                        /*
            <div
                className=""
                style={{
                    padding: "0px 32px",
                    marginTop: 24,
                    marginBottom: 10,
                }}
            >
            
            <NewsletterSubscribe />
            </div>
                        */
                    }  

            <div
                className="lc-xittol e15rqx34"
                style={{
                    padding: "0px 16px",
                    marginBottom: 10,
                    paddingBottom: 48
                }}
            >

                {widgetData?.button_links?.length > 0 &&

                    <h2
                        className="lc-1qcaodq e184w8cj5"
                        style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            padding: "0px 24px",
                            marginTop: 24,
                            color: "rgb(17, 17, 17)"
                        }}
                    >
                        <div
                            className="lc-1laupg6 efrsfol0 pikalinkitTitle"
                            style={{
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            {t("Pikalinkit")}
                            <FaSearch style={{ marginLeft: 6 }} />
                        </div>
                    </h2>}

                {widgetData?.button_links?.length > 0 &&
                    ButtonLinksSection()}

            </div>
        </div>
    </div>;
}

function aloitusKysymyksetComponent(widgetData, setInnerWindowOpen, setOpeningMessage) {
    return <div
        className="lc-xittol e15rqx34"
        style={{
            padding: "0px 32px",
            marginBottom: 16
        }}
    >
        <h2
            className="lc-1qcaodq e184w8cj5 aloituskysymyksetSupportTitleh2"
            style={{
                fontSize: 16,
                fontWeight: "bold",
                padding: "0px 8px",
                marginTop: -12,
                marginBottom: 16,
                color: "rgb(17, 17, 17)"
            }}
        >
            <div
                className="lc-1laupg6 efrsfol0 aloituskysymyksetSupportTitle"
                style={{
                    display: "flex",
                    alignItems: "center"
                }}
            >
                {widgetData?.primary_button_action_support_text}
            </div>
        </h2>
        <ul
            data-testid="custom-links-list"
            className="lc-8dnji7 e8ntqdz5 aloituskysymyksetDiv"
            style={{
                display: "flex",
                flexDirection: "column",
                listStyleType: "none",
                margin: 0,
                padding: 0,
                gap: 1
            }}
        >
            {widgetData?.INITIAL_CHAT_OPENING_QUESTIONS?.map((item, index) => {
                // Handle both old (string) and new (object) format
                const text = typeof item === 'string' ? item : item.short_topic_button_title;
                return <li
                    key={index}
                    className="lc-n2elx8 e8ntqdz4 hover-list-item"
                    style={{
                        //backgroundColor: "black",
                        borderBottom: '1px solid #042b602e',
                        borderRadius: 12
                    }}
                >
                    <div
                        onClick={() => {
                            setInnerWindowOpen(true);
                            setOpeningMessage(item);
                        }}
                        role="button"
                        tabIndex={0}
                        className="e8ntqdz3 lc-1edaskn ecc17ou0"
                        style={{
                            border: "1px solid #dfdfdf",
                            wordBreak: "break-word",
                            fontSize: 14,
                            color: "#333333",
                            padding: "12px 16px",
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            cursor: "pointer",
                            fontWeight: 600,
                            borderRadius: 12,
                            background: widgetData?.useDarkTheme ? '#fff' : "rgb(249 249 249)",
                        }}
                    >
                        <span
                            className="lc-4gto0l e8ntqdz1"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 8
                            }}
                        >
                            {text}
                        </span>
                        <FaComment style={{ color: widgetData?.secondary_theme_color ?? '#042b60' }} />
                    </div>
                </li>;
            })}
        </ul>
    </div>;
}

function OpenWidgetButton(handleMinimize, widgetData, isMinimized, customStyles, messages, closedMessages, MAX_OPEN_MESSAGES, handleOpenChatViaNotification, handleCloseNotificationMessage, t) {
    return (
        <motion.button
            className="widgetButton"
            onClick={handleMinimize}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            style={{
                position: 'fixed',
                bottom: '30px',
                right: '30px',
                width: '60px',
                height: '60px',
                borderRadius: '50%',
                backgroundColor: widgetData?.theme_color ?? '#37474F',
                border: 'none',
                cursor: 'pointer',
                display: isMinimized ? 'flex' : 'none',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '0 2px 12px rgba(0,0,0,0.2)',
                zIndex: 9999999,
                ...customStyles
            }}
        >
            <div style={{ position: 'relative' }}>
                <motion.div
                    animate={{ rotate: isMinimized ? 0 : 180 }}
                    transition={{ duration: 0.3 }}
                >
                    {isMinimized ? (
                        <FaComment size="22" color='white' />
                    ) : (
                        <FaChevronDown size="22" color='white' />
                    )}
                </motion.div>

                <AnimatePresence>
                    {messages.filter(msg => !closedMessages.has(msg.id)).length > 0 && (
                        <motion.div
                            initial={{ scale: 0 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0 }}
                            style={{
                                position: 'absolute',
                                top: -8,
                                right: -8,
                                backgroundColor: '#FF3B30',
                                color: 'white',
                                borderRadius: '50%',
                                width: '20px',
                                height: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                border: '2px solid white',
                                overflow: 'hidden'
                            }}
                        >
                            {messages.filter(msg => !closedMessages.has(msg.id)).length}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>

            <AnimatePresence>
                {isMinimized && messages && (
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 20 }}
                        style={bubbleStyle}
                    >
                        {messages
                            .filter(msg => !closedMessages.has(msg.id))
                            .slice(-MAX_OPEN_MESSAGES)
                            .map((msg, index) => (
                                <motion.div
                                    key={msg.id}
                                    initial={{ opacity: 0, x: -20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: 20 }}
                                    whileHover={{
                                        scale: 1.02,
                                        boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                                    }}
                                    style={{
                                        position: 'relative',
                                        backgroundColor: 'white',
                                        padding: '16px',
                                        marginBottom: '8px',
                                        borderRadius: '12px',
                                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                                        cursor: 'pointer'
                                    }}
                                    onClick={(e) => handleOpenChatViaNotification(msg, e)}
                                >
                                    <motion.button
                                        whileHover={{
                                            backgroundColor: 'rgba(0,0,0,0.05)',
                                            scale: 1.1
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleCloseNotificationMessage(msg.id, e);
                                        }}
                                        style={{
                                            position: 'absolute',
                                            top: '8px',
                                            right: '8px',
                                            background: 'none',
                                            border: 'none',
                                            padding: '4px',
                                            cursor: 'pointer',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <FaTimes size={12} color="#999" />
                                    </motion.button>

                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '8px',
                                            marginBottom: '4px',
                                            color: '#1a73e8',
                                            paddingRight: '20px'
                                        }}
                                    >
                                        <FaComment /> {t("Uusi viesti!")}
                                    </motion.div>

                                    <motion.div
                                        style={{
                                            marginTop: '4px',
                                            textAlign: "left"
                                        }}
                                    >
                                        {msg.message}
                                    </motion.div>
                                </motion.div>
                            ))}
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.button>
    );
}