import React, { useState, useEffect, useRef } from 'react';
import { Button, Avatar, Panel as RsuitePanel, Dropdown } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import styled from 'styled-components';
import axios from 'axios';
import { getApiUrl } from '../../config';
import MultiStepForm from './MultiStepForm';
import { useAnalytics } from './AnalyticsContext';
import BottomSheet from './BottomSheet'; // <--- Import the new component here

const ScrollContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  padding-bottom: 40px; // Add extra padding at the bottom
  position: relative;
  max-height: calc(100% - ${props => props.buttonheight || 0}px);
  -webkit-overflow-scrolling: touch;

  /* Create a padding container for the content */
  &::after {
    content: '';
    display: block;
    height: 20px; // Additional space after the last message
  }

  /* Firefox scrollbar */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.0) transparent;

  margin-bottom: ${props => props.buttonheight}px;
  transition: margin-bottom 0.3s ease;
`;

const Panel = styled(RsuitePanel)`
  height: 100%;
  height: 100%; // Take up the full parent height
  overflow: hidden; // Prevent content overflow
  position: relative; // Ensure it positions relative to the parent
`;


const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${props => props.backgroundColor ? props.backgroundColor : '#042b60'};
  padding: 14px;
  z-index: 10;
  overflow-y: auto;
  transform: translateY(${props => props.isExiting ? '100%' : '0'});
  transition: all 0.3s ease-out;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-top: 20px; 
  max-height: 400px;
  border-top : 1px solid #d7d7d94d;
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: ${props => props.hasButtons ? '0' : '20px'}; // Add padding when no buttons
`;

const AnimatedButton = styled.button`

  padding: 12px 14px;
  background-color: ${props => props.themeColor || '#007bff'};
  border: none;
  border-radius: 32px;
  color: white;
  cursor: pointer;
  text-align: center;
  display: block;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  min-width: 120px;
  max-width: 300px;
`;

const ChatMessage = styled.div`
  @keyframes appearMessage {
    0% {
      opacity: 0;
      transform: scale(0.96) ${props => props.type === 'user' ? 'translateX(8px)' : 'translateX(-8px)'};
    }
    100% {
      opacity: 1;
      transform: scale(1) translateX(0);
    }
  }

  margin-bottom: ${props => props.type === 'user' ? '10px' : '5px'};
  margin-top: ${props => props.type === 'user' ? '10px' : '5px'};
  display: flex;
  flex-direction: ${props => props.type === 'user' ? 'row-reverse' : 'row'};
  align-items: flex-start;
  gap: 10px;
  position: relative;
  opacity: 0;
  animation: appearMessage 0.2s cubic-bezier(0.33, 1, 0.68, 1) forwards;
  animation-delay: ${props => props.delay};
`;


const extractBaseURL = (url) => {
    try {
        // Find the position of first '/' after the protocol
        const protocolEnd = url.indexOf('//');
        if (protocolEnd !== -1) {
            const pathStart = url.indexOf('/', protocolEnd + 2);
            if (pathStart !== -1) {
                return url.substring(0, pathStart + 1);
            }
        }
        // If no path is found, return the original URL with trailing slash
        return url.endsWith('/') ? url : url + '/';
    } catch (error) {
        return '';
    }
};

const isColorDark = (color) => {
    // Handle null/undefined color
    if (!color) return false;

    // Convert hex to RGB
    let r, g, b;
    if (color.startsWith('#')) {
        const hex = color.replace('#', '');
        r = parseInt(hex.substr(0, 2), 16);
        g = parseInt(hex.substr(2, 2), 16);
        b = parseInt(hex.substr(4, 2), 16);
    } else if (color.startsWith('rgb')) {
        // Handle rgb/rgba format
        const values = color.match(/\d+/g);
        r = parseInt(values[0]);
        g = parseInt(values[1]);
        b = parseInt(values[2]);
    } else {
        return false;
    }

    // Calculate relative luminance using the formula
    // Darker colors have lower luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance < 0.5;
}

const TypingIndicator = () => (
    <div style={{
        display: 'flex',
        gap: '5px',
        padding: '14px',
        background: 'white',
        borderRadius: '10px',
        width: 'fit-content',
    }}>
        <div style={{
            width: '8px',
            height: '8px',
            backgroundColor: '#2196F3',
            borderRadius: '50%',
            animation: 'bounce 1s infinite'
        }} />
        <div style={{
            width: '8px',
            height: '8px',
            backgroundColor: '#2196F3',
            borderRadius: '50%',
            animation: 'bounce 1s infinite',
            animationDelay: '0.2s'
        }} />
        <div style={{
            width: '8px',
            height: '8px',
            backgroundColor: '#2196F3',
            borderRadius: '50%',
            animation: 'bounce 1s infinite',
            animationDelay: '0.4s'
        }} />
        <style>{`
      @keyframes bounce {
        0%, 100% { transform: translateY(0); }
        50% { transform: translateY(-5px); }
      }
    `}</style>
    </div>
);

export const ChatAvatar = ({ logoUrl, defaultText = 'B', isVisible = true }) => {
    const [imageError, setImageError] = useState(false);
    const showDefaultText = !logoUrl || imageError;

    return (
        <Avatar
            circle
            size="sm"
            src={showDefaultText ? null : logoUrl}
            alt="Chat Avatar"
            style={{
                backgroundColor: defaultText === 'B' ? 'transparent' : 'lightgray',
                padding: '2px',
                border: '1px solid #bfbfbf',
                color: "#fff",
                visibility: isVisible ? 'visible' : 'hidden'
            }}
            onError={() => setImageError(true)}
        >
            {showDefaultText && defaultText}
        </Avatar>
    );
};

const ChatSimple = ({ openingMessage, widgetData, chatKey, innerWindowOpen }) => {
    const [messages, setMessages] = useState([]);
    const [currentOptions, setCurrentOptions] = useState(null);
    const [allFormFields, setAllFormFields] = useState(null);
    const [currentStepId, setCurrentStepId] = useState(openingMessage?.topicId ?? "start");
    const [chatFlow, setChatFlow] = useState({});
    const messagesEndRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const timeoutIds = useRef([]);
    const [isExitingButtons, setIsExitingButtons] = useState(false);
    const buttonContainerRef = useRef(null);
    const [buttonHeight, setButtonHeight] = useState(0);
    const analytics = useAnalytics();
    const [currentForm, setCurrentForm] = useState(null);
    const [showSheet, setShowSheet] = useState(false);

    // Show/hide bottom sheet when a form is present.
    useEffect(() => {
        if (currentForm) {
            setShowSheet(true);
        } else {
            setShowSheet(false);
        }
    }, [currentForm]);

    const handleCloseSheet = () => {
        // If form is "required" and you truly don't want them to close,
        // simply do nothing here. Otherwise:
        setShowSheet(false);
        setCurrentForm(null);
    };

    // Set button height on current options change.
    useEffect(() => {
        if (buttonContainerRef.current) {
            setButtonHeight(buttonContainerRef.current.offsetHeight);
        } else {
            setButtonHeight(0);
        }
        return () => {
            setButtonHeight(0);
        };
    }, [currentOptions]);

    // Helper to create trackable timeouts.
    const createTimeout = (callback, delay) => {
        const id = setTimeout(callback, delay);
        timeoutIds.current.push(id);
        return id;
    };

    // Clear all timeouts.
    const clearAllTimeouts = () => {
        timeoutIds.current.forEach(id => clearTimeout(id));
        timeoutIds.current = [];
    };

    // Calculate delay based on message length.
    const calculateDelay = (message) => {
        const baseDelay = 200;
        const delayPerChar = 20;
        const maxDelay = 1000;
        return Math.min(baseDelay + message.length * delayPerChar, maxDelay);
    };

    // Track chat loaded event when ChatSimple mounts.
    useEffect(() => {
        analytics.trackEvent('chat_loaded', { stepId: currentStepId });
    }, []);

    // Initialize chat when innerWindowOpen is true.
    useEffect(() => {
        const initializeChat = async () => {
            clearAllTimeouts();
            setMessages([]);
            setCurrentOptions(null);
            setCurrentForm(null);

            let parsedFlow = {};
            if (widgetData?.predefined_ai_chat_json?.length > 0) {
                try {
                    parsedFlow = JSON.parse(widgetData.predefined_ai_chat_json);
                    setChatFlow(parsedFlow);
                } catch (error) {
                    console.error('Failed to parse chat flow:', error);
                    return;
                }
            }

            // Wait a tick to ensure chatFlow is set.
            await new Promise(resolve => setTimeout(resolve, 1));

            // Set the opening message if provided.
            if (openingMessage?.short_topic_button_title) {
                setMessages([{
                    type: 'user',
                    content: openingMessage.short_topic_button_title,
                    timestamp: new Date()
                }]);
            }

            // Immediately track a chat start event.
            analytics.trackEvent('chat_start', { initialStepId: openingMessage?.topicId ?? "start", url: window.location.href });

            // Proceed to first step.
            const initialStepId = openingMessage?.topicId ?? "start";
            if (parsedFlow[initialStepId]) {
                await proceedToNextStep(initialStepId, parsedFlow);
            }
        };

        if (innerWindowOpen) {
            initializeChat();
        }
    }, [openingMessage, widgetData, chatKey, innerWindowOpen]);

    const onCloseBottomSheet = () => {
        setShowSheet(false);
        setCurrentForm(null);
        setMessages(prev => [...prev, {
            type: 'bot',
            content: "Miten haluat edetä?",
            timestamp: new Date()
        }]);
        const options = widgetData?.INITIAL_CHAT_OPENING_QUESTIONS.map(q => ({
            text: q?.short_topic_button_title,
            nextStep: q?.topicId,
        }));
        setCurrentOptions(options);
        analytics.trackEvent('sheet_closed', {});
    };

    const proceedToNextStep = async (stepId, parsedFlow) => {
        return new Promise(async (resolve) => {
            setCurrentForm(null);
            setCurrentOptions(null);

            if (!stepId) {
                console.warn('No stepId provided to proceedToNextStep');
                resolve();
                return;
            }

            const nextStep = parsedFlow[stepId];
            if (!nextStep) {
                console.warn(`Step ${stepId} not found in chatFlow`);
                resolve();
                return;
            }

            // Simulate typing indicator by sending bot messages sequentially.
            const sendMessage = async (content, type, timestamp) => {
                setIsLoading(true);
                const delay = calculateDelay(content);
                await new Promise(resolve => createTimeout(resolve, delay));
                setIsLoading(false);
                setMessages(prev => [...prev, { type, content, timestamp }]);
                await new Promise(resolve => createTimeout(resolve, 100));
                setIsLoading(true);
            };

            const sendMessagesSequentially = async (contentArray, type, timestamp) => {
                for (const content of contentArray) {
                    await sendMessage(content, type, timestamp);
                }
            };

            const timestamp = new Date();
            if (Array.isArray(nextStep.content)) {
                await sendMessagesSequentially(nextStep.content, nextStep.type, timestamp);
            } else {
                await sendMessage(nextStep.content, nextStep.type, timestamp);
            }
            setIsLoading(true);
            await new Promise(resolve => createTimeout(resolve, 400));
            setIsLoading(false);
            setCurrentStepId(stepId);

            // After sending bot messages, track a 'bot_message_sent' event.
            analytics.trackEvent('bot_message_sent', { stepId, content: nextStep.content });

            setIsExitingButtons(false);
            if (nextStep.action) {
                if (nextStep.action.type === 'form') {
                    await new Promise(resolve => createTimeout(resolve, 600));
                    setCurrentForm(nextStep.action);
                    setCurrentOptions(null);
                    // Do not resolve; the form submission will continue the flow.
                    return;
                } else if (nextStep.action.type === 'buttons') {
                    setCurrentOptions(nextStep.action.options);
                    setCurrentForm(null);
                } else if (nextStep.action.type === 'link') {
                    window.open(nextStep.action.url, '_blank');
                    await proceedToNextStep(nextStep.action.nextStep, chatFlow);
                    resolve();
                }
            } else {
                setCurrentForm(null);
                setCurrentOptions(null);
                if (nextStep.nextStep) {
                    await proceedToNextStep(nextStep.nextStep, chatFlow);
                    resolve();
                } else {
                    resolve();
                }
            }
        });
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            const scrollContainer = messagesEndRef.current.parentElement;
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
        }
    };

    const submitForm = async (formData) => {
        const payload = {
            widget_id: widgetData?.bot_id,
            notification_email: widgetData?.notification_emails,
            sender_info: {
                url: window.location.href,
                userAgent: navigator.userAgent,
            },
            ...formData,
        };

        const response = await fetch(getApiUrl() + '/widget/send-email', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        });
        if (!response.ok) {
            console.error('Failed to submit form:', response.statusText);
        }
    };

    useEffect(() => {
        const scrollAfterAdjustment = setTimeout(() => {
            scrollToBottom();
        }, 100);
        return () => clearTimeout(scrollAfterAdjustment);
    }, [messages, currentForm, currentOptions, buttonHeight]);

    const handleFormSubmit = async (formData) => {
        setMessages(prev => [
            ...prev,
            { type: 'user', content: '-', timestamp: new Date() }
        ]);

        // Track that user submitted form info.
        analytics.trackEvent('form_submitted', { stepId: currentStepId, formData });
        analytics.trackFormSubmit(formData, currentStepId);

        await new Promise(resolve => createTimeout(resolve, 200));

        setAllFormFields(prevFields => {
            const updatedFields = { ...prevFields, [currentStepId]: formData };
            if (currentForm?.does_submit) {
                submitForm({ form_data: updatedFields });
                return null;
            }
            return updatedFields;
        });

        // Check if current step is part of a multiselect flow
        const isMultiSelectFollowUp = Object.entries(chatFlow).some(([_, step]) => {
            const multiSelectFields = step.action?.fields?.filter(f => f.type === 'multiselect') || [];
            return multiSelectFields.some(field =>
                field.options.some(opt => opt.nextStep === currentStepId)
            );
        });

        if (isMultiSelectFollowUp) {
            // Find the original multiselect step and its selections
            let originalStep, originalSelections;
            for (const [stepId, step] of Object.entries(chatFlow)) {
                const multiSelectFields = step.action?.fields?.filter(f => f.type === 'multiselect') || [];
                if (multiSelectFields.some(field =>
                    field.options.some(opt => opt.nextStep === currentStepId)
                )) {
                    originalStep = step;
                    originalSelections = allFormFields[stepId];
                    break;
                }
            }

            if (originalSelections) {
                // Find the next unprocessed option
                const multiSelectField = originalStep.action.fields.find(f => f.type === 'multiselect');
                const selectedOptions = originalSelections[multiSelectField.name] || [];

                const currentOptionIndex = selectedOptions.findIndex(opt =>
                    opt.nextStep === currentStepId
                );

                if (currentOptionIndex >= 0 && currentOptionIndex < selectedOptions.length - 1) {
                    // Process next option
                    const nextOption = selectedOptions[currentOptionIndex + 1];
                    if (nextOption.nextStep) {
                        await proceedToNextStep(nextOption.nextStep, chatFlow);
                        return;
                    }
                }

                // If we've processed all options, proceed to the original form's nextStep
                if (originalStep.action.nextStep) {
                    await proceedToNextStep(originalStep.action.nextStep, chatFlow);
                    return;
                }
            }
        }

        // Handle initial multiselect selection
        const multiSelectFields = currentForm?.fields?.filter(field => field.type === 'multiselect') || [];
        if (multiSelectFields.length > 0) {
            for (const field of multiSelectFields) {
                const selectedValues = formData[field.name] || [];

                // Find first option with nextStep
                const firstOption = selectedValues.find(value => value.nextStep);
                if (firstOption?.nextStep) {
                    await proceedToNextStep(firstOption.nextStep, chatFlow);
                    return;
                }
            }
        }

        const formNextStep = currentForm?.nextStep;
        if (formNextStep) {
            await proceedToNextStep(formNextStep, chatFlow);
        }
    };

    const handleOptionClick = async (option) => {
        setIsExitingButtons(true);
        setMessages(prev => [
            ...prev,
            { type: 'user', content: option.text, timestamp: new Date() }
        ]);

        // Instead of trackMessage, we track a dedicated 'option_clicked' event.
        analytics.trackEvent('option_clicked', {
            optionText: option.text,
            stepId: currentStepId,
            nextStep: option.nextStep
        });

        await proceedToNextStep(option.nextStep, chatFlow);
        await new Promise(resolve => setTimeout(resolve, 200));
        setIsExitingButtons(false);
    };

    useEffect(() => {
        return () => {
            analytics.endChat();
        };
    }, []);

    console.log(currentOptions, "currentOptions");

    return (
        <Panel>
            <ChatContainer>
                <ScrollContainer buttonheight={showSheet ? 0 : buttonHeight}>
                    {messages.map((message, index) => {
                        const prevMessage = messages[index - 1];
                        const isVisible = !prevMessage || prevMessage.type !== message.type;
                        return (
                            <ChatMessage key={index} delay={`${0.1}s`} type={message.type}>
                                {message.type !== 'user' &&
                                    <ChatAvatar
                                        isVisible={isVisible}
                                        logoUrl={message.type === 'user' ? null : widgetData?.logo}
                                        defaultText={message.type === 'user' ? 'U' : 'B'}
                                    />
                                }
                                <div
                                    style={{
                                        maxWidth: '70%',
                                        padding: '10px',
                                        position: 'relative',
                                        backgroundColor: message.type === 'user' ? widgetData?.secondary_theme_color : '#fff',
                                        borderRadius: '12px',
                                        borderTopRightRadius: message.type === 'user' ? '2px' : '12px',
                                        borderTopLeftRadius: message.type === 'user' ? '12px' : '2px',
                                        marginLeft: message.type === 'user' ? 'auto' : '0',
                                        border: '1px solid #d7d7d94d',
                                        fontWeight: message.type === 'user' ? 600 : '400',
                                        color: message.type === 'user'
                                            ? isColorDark(widgetData?.secondary_theme_color) ? '#f1f1f1' : '#333333'
                                            : '#333333'
                                    }}
                                >
                                    <p style={{ margin: 0, overFlow: "hidden", whiteSpace: "pre-wrap" }}>{message.content}</p>
                                    <small style={{
                                        fontSize: '0.7em',
                                        color: message.type === 'user'
                                            ? isColorDark(widgetData?.secondary_theme_color) ? '#e6e6e6' : '#333333'
                                            : '#333333'
                                    }}>
                                        {message?.timestamp?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                    </small>
                                </div>
                            </ChatMessage>
                        );
                    })}
                    {isLoading && (
                        <div style={{
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'flex-start',
                            background: 'transparent'
                        }}>
                            <ChatAvatar logoUrl={widgetData?.logo} />
                            <TypingIndicator />
                        </div>
                    )}
                    <div ref={messagesEndRef} />
                </ScrollContainer>

                {currentOptions && (
                    <ButtonContainer backgroundColor={widgetData?.chatButtonBackgroundColor} ref={buttonContainerRef} isExiting={isExitingButtons} className="buttonContainer">
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            gap: '10px',
                            width: '100%',
                            justifyContent: 'right'
                        }}>
                            {currentOptions.map((option, idx) => {
                                const nextStep = chatFlow[option?.nextStep];
                                const isExternalLink = nextStep?.action?.type === "link";
                                return (
                                    <AnimatedButton
                                        key={idx}
                                        className="selectionButton"
                                        onClick={() => handleOptionClick(option)}
                                        themeColor={widgetData?.theme_color}
                                        delay={idx * 100}
                                        style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                                        aria-label={isExternalLink ? `${option.text} (opens in new tab)` : option.text}
                                    >
                                        <span style={{ color: widgetData?.chatSelectionButtonTextColor || 'inherit' }}>{option.text}</span>
                                        {isExternalLink && (
                                            <span aria-hidden="true" style={{ fontSize: '0.85em', marginLeft: '2px', display: 'inline-flex' }}>
                                                <svg width="14" height="14" viewBox="0 0 24 24" fill="currentColor">
                                                    <path d="M5 3c-1.093 0-2 .907-2 2v14c0 1.093.907 2 2 2h14c1.093 0 2-.907 2-2v-7h-2v7H5V5h7V3H5zm9 0v2h3.586l-9.293 9.293 1.414 1.414L19 6.414V10h2V3h-7z" />
                                                </svg>
                                            </span>
                                        )}
                                    </AnimatedButton>
                                );
                            })}
                        </div>
                    </ButtonContainer>
                )}

                <BottomSheet isVisible={showSheet} onClose={onCloseBottomSheet} bgColor={widgetData?.chatButtonBackgroundColor}>
                    {currentForm && (
                        <MultiStepForm
                            formConfig={currentForm}
                            onSubmit={handleFormSubmit}
                            widgetData={widgetData}
                        />
                    )}
                </BottomSheet>
            </ChatContainer>
        </Panel>
    );
};

export default ChatSimple;
